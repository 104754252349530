import { accountsApi } from "./NetworkUtils";
import { captureException } from "./devUtils";
import { setSavedLocale } from "./sharedPrefrences";

export type Locales = "is" | "en_GB" | "de";
let locale: Locales = "en_GB";

interface ITranslationObj {
  is: {} | undefined;
  en_GB: {} | undefined;
  de: {} | undefined;
}

const translationFiles: ITranslationObj = {
  is: undefined,
  de: undefined,
  en_GB: undefined,
};

const isValidLocale = (value: string): value is Locales => {
  return ["is", "en_GB", "de"].includes(value);
};

export const setLocale = async (_locale: Locales) => {
  locale = _locale;
  window.localStorage.setItem("locale", _locale);
  await loadTranslations(locale);
  try {
    var req = { locale: _locale };
    await accountsApi.accountsUserSetLocaleCreate(req);
  } catch (e) {
    //
  }
};

export const getLocale = (): Locales => {
  locale = (window.localStorage.getItem("locale") as Locales) ?? "en_GB";
  return locale;
};



// if no translation in lang, try in english
export const t = (key: string, _locale?: Locales): string => {
  let data: any | undefined = new Map();
  if (translationFiles[_locale ?? locale]) {
    data = translationFiles[_locale ?? locale];
  } else {
    loadTranslations(_locale);
    return key;
  }

  let translated: string | string[] =
    data !== null && data[key] ? data[key] ?? key : key;
  if (translated === key && locale in translationFiles) {
    data = translationFiles[locale];
    if (data && key in data && data[key]) {
      return data[key];
    }
  }
  if (Array.isArray(translated)) {
    if (translated[0].length === 0 && _locale !== "en_GB") {
      return t(key, "en_GB");
    }
    return translated[0].length === 0 ? key : translated[0];
  }
  if (!translated) {
    return key;
  }
  if (typeof translated === "string" && translated.trim().length === 0) {
    return key;
  }
  return typeof translated === "string" ? translated : key;
};

const _isLoadingTrans = {
  is: false,
  en_GB: false,
  de: false,
};

export const loadTranslations = async (userLocale?: Locales) => {
  let _savedLocel = window.localStorage.getItem("locale");

  // Determine the locale to use
  if (_savedLocel && isValidLocale(_savedLocel)) {
    locale = _savedLocel; // Use saved locale if valid
  } else if (!userLocale) {
    const lang = navigator.language.substring(0, 2);
    if (lang === "de" || lang === "is") {
      locale = lang as Locales;
      setSavedLocale(locale);
    } else {
      locale = "en_GB";
      setSavedLocale(locale);
    }
  } else if (isValidLocale(userLocale)) {
    locale = userLocale;
  } else {
    locale = "en_GB"; // Default to English
    setSavedLocale(locale);
  }

  // Prevent duplicate loading
  if (_isLoadingTrans[locale]) {
    return;
  }
  _isLoadingTrans[locale] = true;

  // Skip if already loaded
  if (translationFiles[locale]) {
    _isLoadingTrans[locale] = false;
    return;
  }

  try {
    // Fetch the correct file
    const fetchLocaleString = locale.toLowerCase(); // Adjust case if needed for file paths
    const res = await fetch(`/translation/${fetchLocaleString}/${fetchLocaleString}.json`);

    if (!res.ok) {
      throw new Error(`Failed to load translation for locale ${locale}. Status: ${res.status}`);
    }

    // Parse JSON safely
    const data = await res.json();
    if (data && data["locale_data"] && data["locale_data"]["messages"]) {
      translationFiles[locale] = data["locale_data"]["messages"];
    } else {
      throw new Error(`Invalid translation file format for locale ${locale}`);
    }
  } catch (e) {
    console.error(`Error loading translations for locale ${locale}:`, e);
    captureException(e);
  } finally {
    _isLoadingTrans[locale] = false;
  }
};


export const loadEnTranslations = async (): Promise<String> => {
  if (_isLoadingTrans["en_GB"]) {
    return Promise.resolve("already loading en");
  }
  _isLoadingTrans["en_GB"] = true;
  // if already loaded
  if (translationFiles["en_GB"]) {
    _isLoadingTrans["en_GB"] = false;
    return Promise.resolve("Already loaded en");
  }

  try {
    // var res = await fetch("/translation/" + locale + "/" + locale + ".json");
    // var res = await fetch("/translation/trans_" + locale + ".json");
    var res = await fetch("/translation/en_gb/en_gb.json");
    let data = await res.json();
    translationFiles[locale] = data["locale_data"]["messages"];
    _isLoadingTrans[locale] = false;
    return Promise.resolve("success");
  } catch (e) {
    captureException(e);
    _isLoadingTrans[locale] = false;
    return Promise.resolve("not success");
  }
};
